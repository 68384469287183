import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>FIXED BRIDGES</h2>
      <h3>What are they?</h3>
      <p>A bridge fixes a replacement tooth (or teeth) to one or more remaining natural teeth or implants. Some bridges have crowns at each end. Others are fixed to the surface of the teeth next to the gap.  Sometimes a bridge is only fixed to the tooth on one side of the gap.</p>
      <p>Bridges are made of metal and porcelain or sometimes just porcelain.</p>
      <h3>What will my dentist do?</h3>
      <p>There are several stages in making a bridge:</p>
      <p>The dentist uses a soft, mouldable material  to take impressions of your mouth. A dental technician makes exact plaster models of your upper and lower teeth and gums, which show how your teeth bite together.</p>
      <p>The teeth that will support  the bridge are prepared to take the fixings and to make sure that the bridge is not too bulky.</p>
      <p>Another impression is taken of the teeth and any gaps, and the dental technician uses this to make the bridge. A plastic temporary bridge or temporary crown may be fitted in the meantime.</p>
      <p>At your final visit, the dentist will check that the bridge fits, make any minor adjustments and then fix it permanently in place. Your dentist or hygienist will show you the best way of keeping your new bridge clean.</p>
      <h3>What are the benefits?</h3>
      <p>A bridge lets you almost  forget that you have missing teeth.</p>
      <p>It can improve  the way you look, bite, chew and speak.</p>
      <p>The teeth can be matched  to the colour of your own teeth.</p>
      <p>A bridge can last many years, if you keep it clean and if there is no accidental damage.</p>
      <p>Natural teeth are protected from wear and tear, and from moving or tilting out of line, which could cause your teeth to bite together incorrectly.</p>
      <p>If you do not want a bridge, you can have a removable partial denture. The dentist will explain how successful a bridge will be. If the supporting teeth are not strong enough, a denture or implant might be better. If you have just had some teeth taken out, a denture might be made first, with a bridge fitted later when the gum has healed.</p>
    </LightboxLayout>
  )
}

export default Page